declare global {
	interface Window {
		gtag: (
			command: "config" | "event",
			targetId: string,
			config?: GtagConfig | GtagEventParams,
		) => void;
	}
}

interface GtagConfig {
	page_path?: string;
}

interface GtagEventParams {
	event_category: string;
	event_label: string;
	value?: number;
}

export const GA_TRACKING_ID = "GTM-PDG8XWK2";

export const pageview = (url: string) => {
	if (typeof window !== "undefined" && window.gtag) {
		window.gtag("config", GA_TRACKING_ID, {
			page_path: url,
		});
	}
};

interface GtagEvent {
	action: string;
	category: string;
	label: string;
	value?: number;
}

export const event = ({ action, category, label, value }: GtagEvent) => {
	if (typeof window !== "undefined" && window.gtag) {
		window.gtag("event", action, {
			event_category: category,
			event_label: label,
			value: value,
		});
	}
};
